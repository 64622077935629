import React from 'react';
import classnames from 'classnames';
import Image from 'next/legacy/image';
import { IOS_APP_LINK, ANDROID_APP_LINK } from 'src/data/parkplus_socials';
import s from './GetTheApp.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type DownloadApp = {
  label: string;
  playstore_img: StrapiImageTypes;
  app_store_img: StrapiImageTypes;
};

type Data = {
  title: string;
  subtitle: string;
  download_app: DownloadApp;
  img: StrapiImageTypes;
};

type GetTheAppProps = {
  data: Data;
};

export const GetTheApp = ({ data }: GetTheAppProps) => (
  <div className={classnames(s.container, 'cmn_container_no_padding')}>
    <div className={s.top}>
      <div className={s.img}>
        <Image
          src={data?.img?.data?.attributes?.url}
          layout="fill"
          objectFit="contain"
          alt={data?.img?.data?.attributes?.alternativeText}
        />
      </div>
    </div>
    <div>
      <h3 className={s.title}>{data.title}</h3>
      <p className={s.subtitle}>{data.subtitle}</p>
      <div>
        <p className={s.label}>{data.download_app.label}</p>
        <div className={s.link}>
          <a href={ANDROID_APP_LINK} target="_blank" rel="noreferrer">
            <div className={s.store_img}>
              <Image
                src={data?.download_app?.playstore_img?.data?.attributes?.url}
                alt="playstore icon"
                layout="fill"
              />
            </div>
          </a>

          <a href={IOS_APP_LINK} target="_blank" rel="noreferrer">
            <div className={s.store_img}>
              <Image
                src={data?.download_app?.app_store_img?.data?.attributes?.url}
                alt="apple app store icon"
                layout="fill"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
);
