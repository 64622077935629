import React, { useRef, useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import Slider from 'react-slick';
import cn from 'classnames';
import Markdown from 'markdown-to-jsx';
import ArrowNextSVG from 'src/common/icons/arrow-next.svg';
import ArrowPrevSVG from 'src/common/icons/arrow-prev.svg';
import s from './UserRating.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type Ratings = {
  id: number;
  stars: number;
  message: string;
  user_name: string;
  user_img: StrapiImageTypes;
  designation: string;
};

type Data = {
  heading: string;
  sub_heading: string;
  ratings: Ratings[];
};

type UserRatingProps = {
  data: Data;
};

export const UserRating = ({ data }: UserRatingProps) => {
  const sliderRef = useRef();
  const [width, setWidth] = useState<number>();
  const [sliderSetting, setSliderSetting] = useState({
    slidesToShow: 1,
    rows: 2,
    slidesPerRow: 2,
  });

  const next = () => {
    //   @ts-ignore
    sliderRef.current.slickNext();
  };

  const previous = () => {
    //   @ts-ignore
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    setWidth(global.innerWidth);
    const handleWindowResize = () => {
      setWidth(global.innerWidth);
    };
    global.addEventListener('resize', handleWindowResize);
    return () => global.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    if (width < 450) {
      setSliderSetting({
        slidesToShow: 1,
        rows: 1,
        slidesPerRow: 1,
      });
    } else {
      setSliderSetting({
        slidesToShow: 1,
        rows: 2,
        slidesPerRow: 2,
      });
    }
  }, [width]);

  return (
    <div className={s.container}>
      <div className={s.top}>
        <Markdown className={s.heading}>{data?.heading}</Markdown>
        <p className={s.sub_heading}>{data?.sub_heading}</p>
      </div>
      <div className={cn(s.wrapper, 'cmn_container_no_padding')}>
        {/* eslint-disable-next-line no-return-assign */}
        <Slider
          ref={(slider) => (sliderRef.current = slider)}
          {...sliderSetting}
        >
          {data.ratings.map((rating) => (
            <div key={rating.id} className={s.rating_wrapper}>
              <div className={s.stars}>
                &#9733; &#9733; &#9733; &#9733; &#9733;
              </div>
              <p className={s.message}>{rating.message}</p>
              <div className={s.user}>
                <div className={s.img}>
                  <Image
                    src={rating?.user_img?.data?.attributes?.url}
                    layout="fill"
                    objectFit="cover"
                    alt={rating?.user_img?.data?.attributes?.alternativeText}
                  />
                </div>
                <div className={s.user_detail}>
                  <p className={s.name}>{rating.user_name}</p>
                  <p className={s.designation}>{rating.designation}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div style={{ textAlign: 'center' }} className={s.action}>
        <button className={s.btn} type="button" onClick={() => previous()}>
          <ArrowPrevSVG />
        </button>
        <button className={s.btn} type="button" onClick={() => next()}>
          <ArrowNextSVG />
        </button>
      </div>
    </div>
  );
};
