import React from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import Markdown from 'markdown-to-jsx';

import { IOS_APP_LINK, ANDROID_APP_LINK } from 'src/data/parkplus_socials';
import { checkMobileOS } from 'core-lib/utils/helpers';
import s from './Hero.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type HeroContent = {
  img: StrapiImageTypes;
  title: string;
  subtitle: string;
  qr_code: any;
  qr_text: string;
};

type HeroProps = {
  heroContent: HeroContent;
};

export const Hero = ({ heroContent }: HeroProps) => {
  const router = useRouter();

  const handleGetAppClick = () => {
    const os = checkMobileOS();
    if (os === 'iOS') {
      router.push(IOS_APP_LINK);
    } else {
      router.push(ANDROID_APP_LINK);
    }
  };

  return (
    <div className={classnames(s.hero)}>
      <Markdown className={s.title2}>{heroContent?.title}</Markdown>

      <div
        className={s.img}
        style={{
          backgroundImage: `url(${heroContent?.img?.data?.attributes?.url})`,
        }}
      />
      <div className={s.hero_content}>
        <Markdown className={s.title}>{heroContent?.title}</Markdown>
        <p className={s.subtitle}>{heroContent?.subtitle}</p>
        <button
          type="button"
          className={s.hero_btn}
          onClick={() => handleGetAppClick()}
        >
          Get the app
        </button>
        <div className={s.qr_code}>
          <div className={s.qr_img}>
            <Image
              src={heroContent?.qr_code?.data?.attributes?.url}
              layout="fill"
              objectFit="contain"
              priority
              alt={heroContent?.qr_code?.alternativeText}
            />
          </div>

          <p className={s.qr_text}>{heroContent?.qr_text}</p>
        </div>
      </div>
    </div>
  );
};
