import React from 'react';
import cn from 'classnames';
import Image from 'next/legacy/image';
import s from './ReasonToLove.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type Reason = {
  id: number;
  content: string;
  img: StrapiImageTypes;
};

type Content = {
  heading: string;
  sub_heading: string;
  reasons: Reason[];
};

type ReasonToLoveProps = {
  content: Content;
};

export const ReasonToLove = ({ content }: ReasonToLoveProps) => (
  <div className={cn(s.container, 'cmn_container_no_padding')}>
    <div className={s.top}>
      <h3 className={s.heading}>{content.heading}</h3>
      <p className={s.sub_heading}>{content.sub_heading}</p>
    </div>
    <div className={s.boxes}>
      {content.reasons.map((reason) => (
        <div key={reason.id} className={s.reasons}>
          <div className={s.img}>
            <Image
              src={reason?.img?.data?.attributes?.url}
              layout="fill"
              priority
              alt={reason?.img?.data?.attributes?.alternativeText}
            />
          </div>
          <p className={s.content}>{reason.content}</p>
        </div>
      ))}
    </div>
  </div>
);
