import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import s from './Section2.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type Items = {
  id: number;
  title: string;
  subtitle: string;
  img: any;
  hex_color: string;
  url: string;
  loader_color: string;
  thumbnail: StrapiImageTypes;
};

type SectionContent = {
  items: Items[];
  title: string;
};

type SectionProps = {
  sectionContent: SectionContent;
};

export const Section2 = ({ sectionContent }: SectionProps) => {
  const router = useRouter();
  const [items] = useState(sectionContent.items);
  const [activeItem, setActiveItem] = useState(items[0]);
  const [stopTimer, setStopTimer] = useState(false);

  let timer;

  const handleItemClick = (e, item: Items) => {
    e.preventDefault();
    setStopTimer(true);
    if (activeItem?.id === item.id) {
      if (item.url) {
        router.push(item.url);
      }
    } else {
      setActiveItem(item);
    }
  };

  const changeActiveSection = () => {
    if (stopTimer) return;
    let i = 1;
    timer = setInterval(() => {
      setActiveItem(items[i]);
      i += 1;
      if (i >= items.length) {
        i = 0;
        setStopTimer(true);
      }
    }, 10000);
  };

  useEffect(() => {
    if (!stopTimer) {
      changeActiveSection();
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [stopTimer]);

  return (
    <div>
      <h2 className={classnames(s.heading, 'cmn_container_no_padding')}>
        {sectionContent.title}
      </h2>
      <div className={classnames(s.container, 'cmn_container_no_padding')}>
        <div className={s.left}>
          {sectionContent.items.map((section) => (
            <div
              className={classnames(s.left_list, {
                [s.active_list]: activeItem?.id === section.id,
              })}
              onClick={(e) => handleItemClick(e, section)}
              key={section.id}
              style={{
                backgroundColor:
                  section.id === activeItem?.id ? section.hex_color : null,
              }}
            >
              <div className={s.section_title}>
                <h2
                  className={classnames(s.title, {
                    [s.active_title]: section.id !== activeItem?.id,
                  })}
                >
                  {section.title}
                </h2>
                {activeItem?.id === section.id && section?.url?.length > 0 && (
                  <p className={s.learn_more}>Learn More</p>
                )}
              </div>

              {activeItem?.id === section.id && (
                <p className={s.subtitle}>{section.subtitle}</p>
              )}
              {activeItem?.id === section.id && !stopTimer && (
                <div
                  className={s.timebar}
                  style={{ backgroundColor: section.loader_color }}
                />
              )}
            </div>
          ))}
        </div>
        <div className={s.right}>
          {activeItem?.img && (
            <video
              src={`${activeItem?.img?.data?.attributes?.url}`}
              autoPlay
              className={s.image}
              muted
              playsInline
              poster={`${activeItem?.thumbnail?.data?.attributes?.url}`}
              loop
            />
          )}
        </div>
      </div>
    </div>
  );
};
