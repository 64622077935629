export const TAB_LIST = [
  {
    id: 1,
    name: 'Corporates',
  },
  {
    id: 2,
    name: 'Societies',
  },
  {
    id: 3,
    name: 'Parking owners',
  },
  {
    id: 4,
    name: 'Malls/Hotels',
  },
];
