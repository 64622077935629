/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import ArrowDownSVG from 'src/common/icons/arrow-down.svg';
import s from './Section.module.scss';
import ChevronDown1 from 'ui/components/newIcons/ChevronDown1';

type Items = {
  id: number;
  title: string;
  subtitle: string;
  hex_color: string;
  img: any;
  url: string;
  thumbnail: any;
};

type SectionContent = {
  items: Items[];
  title: string;
};

type SectionProps = {
  sectionContent: SectionContent;
};

export const Section = ({ sectionContent }: SectionProps) => {
  const router = useRouter();
  const [activeItem, setActiveItem] = useState(null);
  const [showVideo, setShowVideo] = useState(false);

  const handleListItemClick = (e, item: Items) => {
    e.preventDefault();

    if (activeItem === item.id) {
      if (item.url?.includes('https')) {
        global.location.href = item.url;
      } else if (item.url) {
        router.push(item.url);
      }
    } else {
      setActiveItem(item.id);
    }
  };

  const renderVideo = () => {
    if (global.scrollY > 10) {
      setShowVideo(true);
    }
  };

  useEffect(() => {
    setActiveItem(sectionContent.items[0].id);
  }, []);

  useEffect(() => {
    global.window.addEventListener('scroll', renderVideo);
    return () => {
      global.window.removeEventListener('scroll', renderVideo);
    };
  }, []);

  const url = sectionContent.items[0].img?.data?.attributes?.url;

  return (
    <div className={s.container}>
      <h2 className={classnames(s.heading, 'cmn_container_no_padding')}>
        {sectionContent.title}
      </h2>
      {sectionContent.items.map((section) => {
        const videoUrl = `${section?.img?.data?.attributes?.url}`;
        const thumbUrl = `${section?.img?.data?.attributes?.url}`;
        return (
          <div
            className={classnames(s.list, {
              [s.active_list]: section.id === activeItem,
            })}
            onClick={(e) => handleListItemClick(e, section)}
            key={section.id}
            style={{
              backgroundColor:
                section.id === activeItem ? section.hex_color : null,
            }}
          >
            {activeItem === section.id && showVideo && videoUrl && (
              <video
                src={videoUrl}
                autoPlay
                className={s.img}
                muted
                loop
                playsInline
                poster={thumbUrl}
              />
            )}
            <div className={s.section_title}>
              <h2
                className={classnames(s.title, {
                  [s.active_title]: section.id === activeItem,
                })}
                // onClick={() => handleTitleClick(section)}
              >
                {section.title}
              </h2>
              {activeItem === section.id && section?.url?.length > 0 && (
                <p className={s.learn_more}>Learn More</p>
              )}
              {/* <ArrowDownSVG
                className={classnames(s.icon, {
                  [s.active]: section.id === activeItem,
                })}
              /> */}
              <div className={classnames({
                  [s.active]: section.id === activeItem,
                })}>
                <ChevronDown1 primaryColor="interaction_button" height={20} width={20} />
              </div>
            </div>
            {activeItem === section.id && (
              <div>
                <p className={s.subtitle}>{section.subtitle}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
