import React from 'react';
import classnames from 'classnames';
import Image from 'next/legacy/image';
import Markdown from 'markdown-to-jsx';
import { useRouter } from 'next/router';
import Link from 'next/link';
import s from './CaseStudies.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type Cases = {
  id: number;
  title: string;
  subtitle: string;
  url: string;
  image: StrapiImageTypes;
};

type Data = {
  heading: string;
  cases: Cases[];
};

type CaseStudiesProps = {
  data: Data;
};

export const CaseStudies = ({ data }: CaseStudiesProps) => {
  const router = useRouter();
  return (
    <div className={classnames(s.container, 'cmn_container_no_padding')}>
      {/* <h3 className={s.title}>{data.heading}</h3> */}
      <Markdown className={s.title}>{data.heading}</Markdown>
      <div className={s.cases}>
        {data.cases.map((cases) => (
          <Link
            href={cases.url}
            prefetch={false}
            key={cases.id}
            className={s.links}
          >
            <div className={s.img}>
              <Image
                src={cases?.image?.data?.attributes?.url}
                layout="fill"
                objectFit="cover"
                alt={cases?.image?.data?.attributes?.alternativeText}
              />
            </div>
            <h3 className={s.case_title}>{cases.title}</h3>
          </Link>
        ))}
      </div>
      <div className={s.action}>
        <button
          type="button"
          className={s.btn}
          onClick={() => {
            router.push('/case-study');
          }}
        >
          See all case studies
        </button>
      </div>
    </div>
  );
};
