import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import { TAB_LIST } from 'src/modules/static/homePage/utils';
import Department from 'src/modules/static/homePage/Department';
import s from './ParkplusForBusiness.module.scss';
import { StrapiImagesTypes } from '../../../../utils/types';

type Section = {
  title: string;
  content: string;
  case_study_label: string;
  case_study_url: string;
  images: StrapiImagesTypes;
};

type Data = {
  heading: string;
  sub_heading: string;
  corporate: Section;
  society: Section;
  parking_owners: Section;
  malls: Section;
  footer: string;
};

type ParkplusForBusinessProps = {
  data: Data;
};

export const ParkplusForBusiness = ({ data }: ParkplusForBusinessProps) => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState(null);

  const handleTabsClick = (id) => {
    if (activeTab === id) {
      setActiveTab(1);
    } else {
      setActiveTab(id);
    }
  };

  const handleBtnClick = () => {
    global.location.hash = '';
    global.location.hash = '#contact-us';
  };

  useEffect(() => {
    setActiveTab(1);
  }, []);

  useEffect(() => {
    if (router.query.active_tab) {
      setActiveTab(Number(router.query.active_tab));
    }
  }, [router.query]);

  return (
    <div className={s.root} id="business">
      <div className={classnames(s.container, 'cmn_container_no_padding')}>
        <h3 className={s.heading}>{data.heading}</h3>
        <p className={s.sub_heading}>{data.sub_heading}</p>

        <div className={s.tab_list}>
          {TAB_LIST.map((tabs) => (
            <button
              className={classnames(s.tab, {
                [s.active]: tabs.id === activeTab,
              })}
              key={tabs.id}
              onClick={() => handleTabsClick(tabs.id)}
              type="button"
            >
              {tabs.name}
            </button>
          ))}
        </div>
      </div>
      {activeTab === 1 && <Department section={data.corporate} />}
      {activeTab === 2 && <Department section={data.society} />}
      {activeTab === 3 && <Department section={data.parking_owners} />}
      {activeTab === 4 && <Department section={data.malls} />}
      <div className={classnames(s.footer, 'cmn_container_no_padding')}>
        <div className={s.footer_wrapper}>
          <p className={s.text}>{data.footer}</p>
          <button
            className={s.btn}
            type="button"
            onClick={() => handleBtnClick()}
          >
            Get in touch
          </button>
        </div>
      </div>
    </div>
  );
};
