import React from 'react';
import classnames from 'classnames';

import s from './Responsibility.module.scss';

type Achievements = {
  id: number;
  value: string;
  description: string;
  img: any;
  label: string;
};

type Data = {
  title: string;
  subtitle: string;
  achievements: Achievements[];
};

type ResponsibilityProps = {
  data: Data;
};

export const Responsibility = ({ data }: ResponsibilityProps) => (
  <div className={classnames(s.root)}>
    <div className={classnames(s.container, 'cmn_container_no_padding')}>
      <div className={s.left}>
        <h3 className={s.title}>{data.title}</h3>
        <p className={s.subtitle}>{data.subtitle}</p>
      </div>
      <div className={s.right}>
        {data.achievements.map((achievement) => (
          <div className={s.achievement} key={achievement.id}>
            <p className={s.value}>
              {achievement.value} {achievement.label}
            </p>
            <p className={s.desc}>{achievement.description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);
