import React from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import Slider from 'react-slick';
import Link from 'next/link';

import ArrowRightSVG from 'src/common/icons/blue-arrrow-right.svg';
import s from './Department.module.scss';
import { StrapiImagesTypes } from '../../../../utils/types';
import ArrowRight from 'ui/components/newIcons/ArrowRight';

type Section = {
  title: string;
  content: string;
  case_study_label: string;
  case_study_url: string;
  images: StrapiImagesTypes;
};

type DepartmentProps = {
  section: Section;
};

const SLIDER_OPTIONS = {
  dots: true,
  infinite: true,
  autoplay: true,
  centerMode: false,
  arrows: true,
};

export const Department = ({ section }: DepartmentProps) => (
  <div className={classnames(s.wrapper, 'cmn_animation_x')}>
    <div className={s.crousel}>
      <Slider {...SLIDER_OPTIONS}>
        {section?.images?.data?.map((image) => (
          <div className={s.img} key={image?.id}>
            <Image
              src={image?.attributes?.url}
              layout="fill"
              objectFit="cover"
              alt={image?.attributes?.alternativeText}
            />
          </div>
        ))}
      </Slider>
    </div>

    <div className={s.bottom}>
      <p className={s.title}>{section?.title}</p>
      <Markdown className={classnames(s.content, 'cmn_markdown_content')}>
        {section?.content}
      </Markdown>
      <div className={s.case_study_wrapper}>
        <Link
          href={section?.case_study_url}
          prefetch={false}
          className={s.case_study}
        >
          <p className={s.case_study_label}>{section?.case_study_label}</p>
          <div className={s.arrow_right}>
            {/* <ArrowRightSVG /> */}
            <div className={s.overlay} />
            <ArrowRight primaryColor="grey_0" height={20} width={24} />
          </div>
        </Link>
      </div>
    </div>
  </div>
);
