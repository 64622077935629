import React from 'react';
import Head from 'next/head';
import qs from 'qs';
import { getMethod } from 'core-lib/utils/api';
import Hero from 'src/modules/static/homePage/Hero';
import NavBarLayout from 'src/components/common/layout/nabar_layout_v1/navbar_layout_v1';
import Section from 'src/modules/static/homePage/Section';
import Section2 from 'src/modules/static/homePage/Section2';
import Responsibility from 'src/modules/static/homePage/Responsibility';
import CaseStudies from 'src/modules/static/homePage/CaseStudies';
import OurPartners from 'src/modules/static/homePage/OurPartners';
import GetTheApp from 'src/modules/static/homePage/GetTheApp';
import ContactUsWrapper from 'src/components/common/contact_us_wrapper';
import ParkplusForBusiness from 'src/modules/static/homePage/ParkplusForBusiness';
import UserRating from 'src/modules/static/homePage/UserRating';
import Stories from 'src/modules/static/homePage/Stories';
import StoriesV2 from 'src/modules/static/homePage/StoriesV2';
import ReasonToLove from 'src/modules/static/homePage/ReasonToLove';
import s from './index.module.scss';
import { STRAPI_ENDPOINT, replaceImageKitUrl } from 'core-lib/data/common';
import MetaIndex from 'src/components/common/MetaIndex';

type HomePageProps = {
  content: any;
};

const HomePage = ({ content }: HomePageProps) => (
  <NavBarLayout
    footer={{ show: true }}
    header={{ show: true }}
    mobileApplicationSchema={{ show: true }}
    siteNavigationSchema={{
      show: true,
      data: { name: 'Home', url: '' },
    }}
    pageHeader={{
      show: true,
      backBtn: { show: false, onClick: () => {} },
      showProfile: true,
      showMenu: true,
    }}
    showProfile={true}
    showContact
    isExpanded
  >
    <div className={s.container}>
      <Head>
        <title>{content?.seo?.meta_title}</title>
        <meta name="description" content={content?.seo?.meta_description} />
        <link rel="canonical" href={content?.seo?.canonical_url} />
        <MetaIndex index={content?.seo?.should_index} />
        <meta
          name="facebook-domain-verification"
          content="ub1f062gwzual8erj4wak5uwmkse15"
        />
      </Head>
      <div className="cmn_container_no_padding">
        <Hero heroContent={content?.hero} />
      </div>
      <Section sectionContent={content?.section} />
      <Section2 sectionContent={content?.section} />
      <Responsibility data={content?.responsibilities} />
      <ReasonToLove content={content?.reason_to_love_parkplus} />
      <ParkplusForBusiness data={content?.parkplus_for_business} />
      <CaseStudies data={content?.case_studies} />
      <OurPartners data={content?.our_clients} />
      <Stories data={content?.stories} />
      <StoriesV2 data={content?.stories} />
      <UserRating data={content?.user_rating} />
      <GetTheApp data={content?.get_the_app} />
      <ContactUsWrapper />
    </div>
  </NavBarLayout>
);

export default HomePage;

export async function getStaticProps() {
  const query = qs.stringify(
    {
      populate: {
        hero: { populate: '*' },
        section: {
          populate: {
            items: { populate: '*' },
          },
        },
        responsibilities: { populate: '*' },
        case_studies: { populate: { cases: { populate: '*' } } },
        our_clients: { populate: { clients: { populate: { logo: '*' } } } },
        get_the_app: {
          populate: {
            download_app: {
              populate: {
                playstore_img: '*',
                app_store_img: '*',
              },
            },
            img: '*',
          },
        },
        parkplus_for_business: {
          populate: {
            corporate: { populate: { images: { populate: '*' } } },
            society: { populate: { images: { populate: '*' } } },
            parking_owners: { populate: { images: { populate: '*' } } },
            malls: { populate: { images: { populate: '*' } } },
          },
        },
        user_rating: { populate: { ratings: { populate: { user_img: '*' } } } },
        stories: {
          populate: {
            quotes_img: { populate: '*' },
            stories_list: { populate: '*' },
          },
        },
        seo: { populate: '*' },
        reason_to_love_parkplus: {
          populate: { reasons: { populate: { img: { populate: '*' } } } },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  const { response }: any = await getMethod(
    `${STRAPI_ENDPOINT}/api/parkplus-home-page?${query}`
  );

  const content = replaceImageKitUrl(response);

  const stickyBanner = content?.stickyBanner || null;

  return {
    props: {
      content: content?.data?.attributes || null,
      stickyBanner,
    },
  };
}
